@import 'base/normalize';

:root {
  --arrow-ratio: 100%;
  --arrow-frames: 10;
  --bunny-ratio: 119%;
  --bunny-frames: 8;
  --cat-ratio: 48.582996%;
  --cat-frames: 8;
}

body {
  background: #fe889f;
}

.scene {
  overflow: hidden;
  position: relative;
  display: none;

  video {
    pointer-events: none;
    width: 100%;
  }

  &.is-active {
    display: block;
  }
}

.scene-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fe889f;
  z-index: 10;
  transform: translate3d(-100%, 0, 0);

  &.is-showing {
    animation: transition-in 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9) forwards;
  }

  &.is-hiding {
    animation: transition-out 0.7s cubic-bezier(0.1, 0.7, 0.6, 0.9) forwards;
  }
}

[data-transition-to] {
  cursor: pointer;
}

.animate-group {
  position: absolute;
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &--br {
    bottom: 5%;
    right: 5%;
    justify-content: flex-end;
  }

  &--bl {
    bottom: 5%;
    left: 5%;
    justify-content: flex-start;
  }

  &--tr {
    top: 5%;
    right: 5%;
    justify-content: flex-end;
  }

  &--tl {
    top: 5%;
    left: 5%;
    justify-content: flex-start;
  }

  &--arrow-l {
    width: 12%;
    left: 16.5%;
    top: 50%;
  }

  &--arrow-r {
    width: 12%;
    right: 15.5%;
    top: 50%;
    transform: scale(-1, 1);
  }
}

.animate-object {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 0;
  animation-play-state: paused;
  animation-iteration-count: infinite;

  &.is-playing {
    animation-play-state: running;
  }
}

.animate-arrow {
  --arrow-percent-width: 100;
  --arrow-offset: calc(100 / var(--arrow-percent-width));
  padding-bottom: calc(var(--arrow-ratio) / var(--arrow-offset));
  width: calc(var(--arrow-percent-width) * 1%);
  background-image: url(../assets/arrow.png);
  background-size: calc(var(--arrow-frames) * 100%);
  animation-name: loop-arrow;
  animation-duration: 1.3s;
  animation-timing-function: steps(var(--arrow-frames));
}

.animate-bunny {
  --bunny-percent-width: 30;
  --bunny-offset: calc(100 / var(--bunny-percent-width));
  padding-bottom: calc(var(--bunny-ratio) / var(--bunny-offset));
  width: calc(var(--bunny-percent-width) * 1%);
  background-image: url(../assets/bunny.png);
  background-size: calc(var(--bunny-frames) * 100%);
  animation-name: loop-bunny;
  animation-duration: 1.3s;
  animation-timing-function: steps(var(--bunny-frames));
}

.animate-cat {
  --cat-percent-width: 50;
  --cat-offset: calc(100 / var(--cat-percent-width));
  padding-bottom: calc(var(--cat-ratio) / var(--cat-offset));
  width: calc(var(--cat-percent-width) * 1%);
  background-image: url(../assets/cat.png);
  background-size: calc(var(--cat-frames) * 100%);
  animation-name: loop-cat;
  animation-duration: 0.8s;
  animation-timing-function: steps(var(--cat-frames));
}

@keyframes loop-arrow {
  100% {
    background-position: calc((100 / (var(--arrow-frames) - 1)) * var(--arrow-frames) * 1%) 0;
  }
}

@keyframes loop-bunny {
  100% {
    background-position: calc((100 / (var(--bunny-frames) - 1)) * var(--bunny-frames) * 1%) 0;
  }
}

@keyframes loop-cat {
  100% {
    background-position: calc((100 / (var(--cat-frames) - 1)) * var(--cat-frames) * 1%) 0;
  }
}

@keyframes transition-in {
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes transition-out {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}
